import React from "react";
import covid19mapslogo from "../../assets/images/covid19mapslogo.png";
import covid19mapsdemo from "../../assets/images/covid19mapsdemo.png";

const Covid19Maps = () => {
  return (
    <>
      <div className="ind-prj-container">
        <div className="prj-abt">
          <h1 className="prj-title" style={{ color: "#4F5AA1" }}>
            COVID-19 MAPS
          </h1>
          <img className="ph" src={covid19mapslogo} alt="" />
          <p>
            A single-page web dashboard that displays current news articles related to Covid-19 information and uses a nested, circular-packing
            tree map to display Covid-19 case data for affected continents and
            countries. Uses a heat-map color scheme for intuitive visualization.
          </p>
          <p style={{ color: "#4F5AA1" }}>(Javascript, React, Redux, D3)</p>
        </div>
        <div className="tech">
          <div className="icon-flex">
            <i
              className="devicon2 devicon-react-original"
              style={{ color: "#4F5AA1" }}
            ></i>
          </div>
          {/* <div className="icon-flex">
                            <i className="devicon2 devicon-nodejs-plain" style={{color:"#4F5AA1"}}></i>
                        </div>
                        <div className="icon-flex">
                            <i className="devicon2 devicon-express-original" style={{color:"#4F5AA1"}}></i>
                        </div>
                        <div className="icon-flex">
                            <i className="devicon2 devicon-postgresql-plain" style={{color:"#4F5AA1"}}></i>
                        </div> */}
          <div className="icon-flex">
            <span
              className="iconify devicon2"
              data-icon="simple-icons:redux"
              data-inline="false"
              style={{ color: "#4F5AA1" }}
            ></span>
          </div>
          <div className="icon-flex">
            <span
              class="iconify devicon2"
              data-icon="cib:d3-js"
              data-inline="false"
              style={{ color: "#4F5AA1" }}
            ></span>
          </div>
        </div>
        <div className="prj-button-container" margin="10px">
          <a
            href="https://covid19maps.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button 
              className="prj-button btn-live hover"
            >
              PROJECT LINK
            </button>
          </a>
          <a
            href="https://github.com/jmtblei/covid19maps"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button 
              className="prj-button btn-live hover"
            >
              SOURCE CODE
            </button>
          </a>
        </div>
        <div className="img-container-single">
          <img className="prj-img-single" src={covid19mapsdemo} alt="" />
        </div>
        {/* <div className="img-container-single">
          <img className="prj-img-singleb" src="https://pfolioimgs.s3-us-west-1.amazonaws.com/c19pre300.png" alt="" />
        </div> */}
      </div>
    </>
  );
};

export default Covid19Maps;
