import React from 'react';
import { Link } from "react-router-dom";
import chatmapslogo from "../../assets/images/chatmapslogo.webp";
import covid19mapslogo from "../../assets/images/covid19mapslogo.png";
import irrlogo from "../../assets/images/irrlogo.png";
import boostertutorlogo from "../../assets/images/boostertutorlogo.png";

const Projects = () => {
    return (
        <>
        <div className="project-container">
            <div className="project">
                <div className="hover">
                    <Link to="/irasshaimase">
                        <div className="project-image">
                            <img className="" src={irrlogo} alt="" />
                        </div>
                    </Link>
                </div>
                <div className="project-title">IRASSHAIMASE!</div>
            </div>
            <div className="project">
                <div className="hover">
                    <Link to="/boostertutor">
                        <div className="project-image">
                            <img className="" src={boostertutorlogo} alt="" />
                        </div>
                    </Link>
                </div>
                <div className="project-title">THE BOOSTER TUTOR</div>
            </div>
            <div className="project">
                <div className="hover">
                    <Link to="/covid-19-maps">
                        <div className="project-image">
                            <img className="" src={covid19mapslogo} alt="" />
                        </div>
                    </Link>
                </div>
                <div className="project-title">COVID-19 MAPS</div>
            </div>
            <div className="project">
                <div className="hover">
                    <Link to="/chatmaps">
                        <div className="project-image">
                            <img className="" src={chatmapslogo} alt="" />
                        </div>
                    </Link>
                </div>
                <div className="project-title">CHATMAPS</div>
            </div>
        </div>
        </>
    );
};

export default Projects;