import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Nav from './Nav/Nav';
import Home from './Pages/Home';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Chatmaps from './Pages/Chatmaps';
import Covid19Maps from './Pages/Covid19Maps';
import BoosterTutor from "./Pages/BoosterTutor";
import Irasshaimase from "./Pages/Irasshaimase";
import Footer from './Footer/Footer'

const View = () => {
    return (
        <div>
            {/* <Header /> */}
            <Nav />
            <Switch>
                <Route exact path="/" render={props => <Home {...props} />} />
                <Route path="/about" render={props => <About {...props} />} />
                <Route path="/work" render={props => <Projects {...props} />} />
                <Route path="/chatmaps" render={props => <Chatmaps {...props} />} />
                <Route path="/covid-19-maps" render={props => <Covid19Maps {...props} />} />
                <Route path="/boostertutor" render={props => <BoosterTutor {...props} />} />
                <Route path="/irasshaimase" render={props => <Irasshaimase {...props} />} />
                {/* <Route path="/contact" render={props => <Contact {...props} />} /> */}
            </Switch>
            <Footer />
        </div>
    )
};

export default View;