import React from "react";
import chatmaps from "../../assets/images/chatmaps.webp";
import chatmapslogo from "../../assets/images/chatmapslogo.webp"

const Chatmaps = () => {
  return (
    <>
        <div className="ind-prj-container">
          <div className="prj-abt">
            <h1 className="prj-title" style={{ color: "#4F5AA1" }}>CHATMAPS</h1>
            <img className="ph" src={chatmapslogo} alt="" />
            <p>
              A location-based mobile chat app for Android that allows users to
              create and join chatrooms based on proximity. Built using Google
              Maps API, SendBird messaging API, and OAuth2 on a Native
              framework.
            </p>
            <p style={{ color: "#4F5AA1" }}>(Javascript, React Native, Node.js, Express, PostgreSQL)</p>
            </div>
            <div className="tech">
              <div className="icon-flex">
                <i
                  className="devicon2 devicon-react-original"
                  style={{ color: "#4F5AA1" }}
                ></i>
                {/* <p className="techwork">React Native</p> */}
              </div>
              <div className="icon-flex">
                <i
                  className="devicon2 devicon-nodejs-plain"
                  style={{ color: "#4F5AA1" }}
                ></i>
                {/* <p className="techwork">Node</p> */}
              </div>
              <div className="icon-flex">
                <i
                  className="devicon2 devicon-express-original"
                  style={{ color: "#4F5AA1" }}
                ></i>
                {/* <p className="techwork">Express</p> */}
              </div>
              <div className="icon-flex">
                <i
                  className="devicon2 fas fa-database"
                  style={{ color: "#4F5AA1" }}
                ></i>
                {/* <p className="techwork">SQL</p> */}
              </div>
              <div className="icon-flex">
                <i
                  className="devicon2 devicon-postgresql-plain"
                  style={{ color: "#4F5AA1" }}
                ></i>
                {/* <p className="techwork">PostgreSQL</p> */}
              </div>
            </div>
            <div className="prj-button-container" margin="10px">
              <a
                href="https://play.google.com/store/apps/details?id=com.labs13localchat&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button 
                  className="prj-button btn-live hover"
                >
                  PROJECT LINK
                </button>
              </a>
              <a
                href="https://github.com/labs13-location-chat/mobile/tree/development"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button 
                  className="prj-button btn-live hover"
                >
                  SOURCE CODE
                </button>
              </a>
            </div>
            <div className="img-container">
              <img className="prj-img-single" src={chatmaps} alt="" />
            </div>
          </div>
    </>
  );
};

export default Chatmaps;
