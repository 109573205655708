import React from 'react';

const Home = () => {
    return (
        <>
            <div className="landing-wrapper">
                <div className="landing-text">
                    <p>
                        BENSON LEI
                    </p>
                    <span></span>                   
                    <p>
                        FULL STACK
                    </p>
                    <p>
                        DEVELOPER
                    </p>
                </div>
                <div className="landing-text-skills">
                    <i className="lts fas fa-lightbulb"></i>
                    <p>SKILLS</p>
                    <i className="lts fas fa-arrow-down"></i>

                </div>
            </div>
            <div>
                <div className="tech techfe">
                    <h3 className="width-flex">Front End</h3>
                    <div className="icon-flex">
                        <span className="iconify devicon" data-icon="simple-icons:react" data-inline="false" style={{color:"#4F5AA1"}}></span>
                        <p>React/Native</p>
                    </div>
                    <div className="icon-flex">
                        <span className="iconify devicon" data-icon="simple-icons:css3" data-inline="false" style={{color:"#4F5AA1"}}></span>
                        <p>CSS3</p>
                    </div>
                    <div className="icon-flex">        
                        <span className="iconify devicon" data-icon="simple-icons:html5" data-inline="false" style={{color:"#4F5AA1"}}></span>
                        <p>HTML5</p>
                    </div>
                    <div className="icon-flex">
                        <span className="iconify devicon" data-icon="simple-icons:javascript" data-inline="false" style={{color:"#4F5AA1"}}></span>
                        <p>JavaScript</p>
                    </div>
                    <div className="icon-flex">
                        <span className="iconify devicon" data-icon="simple-icons:sass" data-inline="false" style={{color:"#4F5AA1"}}></span>
                        <p>Sass</p>
                    </div>
                    <div className="icon-flex">
                        <span className="iconify devicon" data-icon="fa-brands:less" data-inline="false" style={{color:"#4F5AA1"}}></span>
                        <p>Less</p>
                    </div>
                    <div className="icon-flex">
                        <span className="iconify devicon" data-icon="simple-icons:redux" data-inline="false" style={{color:"#4F5AA1"}}></span>
                        <p>Redux</p>
                    </div>
                </div>
                <div className="tech techbe">
                    <h3 className="width-flex">Back End</h3>
                    <div className="icon-flex">
                        <i className="iconify devicon" data-icon="mdi:nodejs" data-inline="false" style={{color:"#4F5AA1"}}></i>
                        <p>Node</p>
                    </div>
                    <div className="icon-flex">
                        <i className="devicon devicon-express-original" style={{color:"#4F5AA1"}}></i>
                        <p>Express</p>
                    </div>
                    <div className="icon-flex">
                        <i className="iconify devicon"
                            data-icon="cib:mongodb"
                            style={{color:"#4F5AA1"}}></i>
                        <p>MongoDB</p>
                    </div>
                    {/* <div className="icon-flex">
                        <i className="iconify devicon"
                            data-icon="logos:knex"
                            style={{color:"#4F5AA1"}}></i>
                        <p>Knex</p>
                    </div> */}
                    <div className="icon-flex">
                        <i className="devicon fas fa-database" style={{color:"#4F5AA1"}}></i>
                        <p>SQL</p>
                    </div>
                    <div className="icon-flex">
                        <i className="iconify devicon" data-icon="simple-icons:postgresql" data-inline="false" style={{color:"#4F5AA1"}}></i>
                        <p>PostgreSQL</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Home;