import React from "react";
import irrlogo from "../../assets/images/irrlogo.png";
import irrorder from "../../assets/images/irrorder.gif";
import irrqueueadmin from "../../assets/images/irrqueueadmin.gif";

const Irasshaimase = () => {
  return (
    <>
      <div className="ind-prj-container">
        <div className="prj-abt">
          <h1 className="prj-title" style={{ color: "#4F5AA1" }}>
            IRASSHAIMASE! <br />
            (いらっしゃいませ！)
          </h1>
          <img className="ph" src={irrlogo} alt="" />
          <p>
            This is a full stack demo for a self-checkout kiosk app intended for
            restaurant owners, to be used by patrons and employees. Created with
            React, React Context, Material-UI frontend, and Node, Express, MongoDB Atlas backend.
          </p>
          <p style={{ color: "#4F5AA1" }}>
            (Javascript, React, React Context, Node, Express, MongoDB)
          </p>
        </div>
        <div className="tech">
          <div className="icon-flex">
            <i
              className="devicon2 devicon-react-original"
              style={{ color: "#4F5AA1" }}
            ></i>
          </div>
          <div className="icon-flex">
            <span
              class="iconify devicon2"
              data-icon="la:node-js"
              data-inline="false"
              style={{ color: "#4F5AA1" }}
            ></span>
          </div>
          <div className="icon-flex">
            <span
              class="iconify devicon2"
              data-icon="simple-icons:express"
              data-inline="false"
              style={{ color: "#4F5AA1" }}
            ></span>
          </div>
          <div className="icon-flex">
            <span
              class="iconify devicon2"
              data-icon="cib:mongodb"
              data-inline="false"
              style={{ color: "#4F5AA1" }}
            ></span>
          </div>
        </div>
        <div className="prj-button-container" margin="10px">
          <a
            href="https://irasshaimase-demo.herokuapp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button 
              className="prj-button btn-live disabled"
              disabled
            >
              PROJECT LINK
              <span className="no-prj-link-text">AS OF 28 NOVEMBER 2022, HEROKU FREE DYNOS HAVE BECOME DEPRACATED AND WILL NO LONGER BE MAINTAINED FOR THIS PROJECT</span>
            </button>
          </a>
          <a
            href="https://github.com/jmtblei/irasshaimasekioskfullstack"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button 
              className="prj-button btn-live hover"
            >
              SOURCE CODE
            </button>
          </a>
        </div>
        <h1 className="prj-title" style={{ color: "#4F5AA1" }}>
          SELF-CHECKOUT
        </h1>
        <p>
          Patrons can place and pay for food orders (Note: this app isn't
          connected to a pay service and doesn't accept payment information;
          it's features is only mimiced).
        </p>
        <div className="img-container-single">
          <img
            className="prj-img-single"
            src={irrorder}
            alt=""
          />
        </div>
        <h1 className="prj-title" style={{ color: "#4F5AA1" }}>
          STATE-MANAGEMENT AND ADMIN VIEW/QUEUE
        </h1>
        <p>
          React-Context is used for state management of orders. Restaurant and
          front-of-house employees may view/cancel/deliver orders through an
          admin screen and display a queue for backlogged/fulfilled orders.
          These are public routes for the purposes of this demo.
        </p>
        <div className="img-container-single">
          <img
            className="prj-img-single"
            src={irrqueueadmin}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Irasshaimase;
