import React from 'react';
const Footer = () => {
    return (
        <div className="footer">
            © BENSON LEI 2022 | CONTACT ME |
            <div className="footer-links">
                <a href="https://github.com/jmtblei" target="_blank" rel="noopener noreferrer">
                    <div className="footer-icons">
                        <i className="lts fab fa-github"></i>
                    </div>
                </a>
                <a href="https://www.linkedin.com/in/jmtblei/" target="_blank" rel="noopener noreferrer">
                    <div className="footer-icons">
                        <i className="lts fab fa-linkedin-in"></i>
                    </div>
                </a>
            </div>
        </div>
    )
}
export default Footer;