import React from 'react';
import './App.css';
import View from './components/View';

function App() {
  return (
    <div className="App">
      <View />
    </div>
  );
}

export default App;
