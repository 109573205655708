import React from "react";
import aboutsplash from "../../assets/images/aboutsplash.jpg";

const About = () => {
  return (
    <>
      <div>
        <img className="abtimg" src={aboutsplash} alt="" />
      </div>
      <div className="abt-text">
        <p>
          <b>Hi! I'm Benson Lei</b>, a San Francisco native and based full stack
          developer. I've worked in multiple separate fields,
          but started here through
          <b> self-interest</b> and <b>curiosity</b>. I've always viewed myself
          as an eternal student, seeking personal growth and valuing active
          learning. The beauty of this field I've found, is it's constantly
          rapid self-advancement. Never a day gone by where I <i>didn't</i>{" "}
          think a highly crafty solution <i>couldn't </i>
          be approached with the help of some new, emerging technology that I've
          yet to learn.
        </p>
        <p>
          My portfolio is a mix of independent and collaborative work that
          outlines the aforementioned and my learning of it throughout. In
          contrary to this swift technological field, You'll find me enjoying
          a mix of youth and retro hobbies like painting, climbing, fishing, and being
          excessively competitive in tabletop and card games when not coding.
        </p>
      </div>
    </>
  );
};
export default About;
