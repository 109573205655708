import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => {
    return (
        <nav className="nav-wrapper">
            <NavLink className="nav-btn" exact to="/">HOME</NavLink>
            <NavLink className="nav-btn" to="/about">ABOUT</NavLink>
            <NavLink className="nav-btn" to="/work">WORK</NavLink>
            {/* <NavLink className="nav-btn" to="/contact">CONTACT</NavLink> */}
        </nav>
    )
};

export default Nav;