import React from 'react';
import boostertutorlogo from "../../assets/images/boostertutorlogo.png";
import boostertutorproductscart from "../../assets/images/boostertutorproductscart.gif";
import boostertutorfiltersortpaginate from "../../assets/images/boostertutorfiltersortpaginate.gif";
import boostertutormobileresponsive from "../../assets/images/boostertutormobileresponsive.gif"


const BoosterTutor = () => {
    return (
        <>
            <div className="ind-prj-container">
                <div className="prj-abt">
                <h1 className="prj-title" style={{ color: "#4F5AA1" }}>
                    THE BOOSTER TUTOR
                </h1>
                <img className="ph" src={boostertutorlogo} alt="" />
                <p>
                    This is a pet-project e-commerce site focused around the sale of Magic: The Gathering booster packs. Written with React / Redux frontend, Node, Express, and MongoDB backend from scratch.
                </p>
                <p style={{ color: "#4F5AA1" }}>(Javascript, React, Redux, Node, Express, MongoDB)</p>
                </div>
                <div className="tech">
                <div className="icon-flex">
                    <i
                    className="devicon2 devicon-react-original"
                    style={{ color: "#4F5AA1" }}
                    ></i>
                </div>
                <div className="icon-flex">
                    <span
                    className="iconify devicon2"
                    data-icon="simple-icons:redux"
                    data-inline="false"
                    style={{ color: "#4F5AA1" }}
                    ></span>
                </div>
                <div className="icon-flex">
                    <span
                    class="iconify devicon2"
                    data-icon="la:node-js"
                    data-inline="false"
                    style={{ color: "#4F5AA1" }}
                    ></span>
                </div>
                <div className="icon-flex">
                    <span
                    class="iconify devicon2"
                    data-icon="simple-icons:express"
                    data-inline="false"
                    style={{ color: "#4F5AA1" }}
                    ></span>
                </div>
                <div className="icon-flex">
                    <span
                    class="iconify devicon2"
                    data-icon="cib:mongodb"
                    data-inline="false"
                    style={{ color: "#4F5AA1" }}
                    ></span>
                </div>
                </div>
                <div className="prj-button-container" margin="10px">
                <a
                    href="https://theboostertutor-demo.herokuapp.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button 
                    className="prj-button btn-live disabled"
                    disabled
                    >
                    PROJECT LINK
                    <span className="no-prj-link-text">AS OF 28 NOVEMBER 2022, HEROKU FREE DYNOS HAVE BECOME DEPRACATED AND WILL NO LONGER BE MAINTAINED FOR THIS PROJECT</span>
                    </button>
                </a>
                <a
                    href="https://github.com/jmtblei/boostertutorfullstack"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button 
                    className="prj-button btn-live hover"
                    >
                    SOURCE CODE
                    </button>
                </a>
                </div>
                <h1 className="prj-title" style={{ color: "#4F5AA1" }}>PRODUCTS</h1>
                <p>Simple, nonrelational database to fetch all products with a single axios api call. Allows the redux state shape to stay flat and reuseable in the frontend, and additional features can be easily added in the future without dropping the database.
                </p>
                <div className="img-container-single">
                <img className="prj-img-single" src={boostertutorproductscart} alt="" />
                </div>
                <h1 className="prj-title" style={{ color: "#4F5AA1" }}>CART</h1>
                <p>Products can be added to carts, whose state is tracked in the reducer and stored in localStorage. This results in no repeated data being stored in the backend, and only one centralized operation. Advantages to this should additional benefits such as user auth, etc. be implemented, is that this allows cart access to anonymous users, while being fetchable when they register/log in.
                </p>
                <h1 className="prj-title" style={{ color: "#4F5AA1" }}>FRONT-END</h1>
                <div className="img-container-single">
                <img className="prj-img-single" src={boostertutorfiltersortpaginate} alt="" />
                </div>
                <p>Dynamic rendering of search filter, sort by, and paginated products using custom React hooks, and full mobile-responsivity with a menu dropdown and backdrop.
                </p>
                <div className="img-container-single">
                <img className="prj-img-single" src={boostertutormobileresponsive} alt="" />
                </div>
                {/* <div className="img-container-single">
                <img className="prj-img-singleb" src="https://pfolioimgs.s3-us-west-1.amazonaws.com/btmobileresponsive.gif"style={{ width: "320px" }} alt="" />
                </div> */}
            </div>
        </>
    )
}

export default BoosterTutor
